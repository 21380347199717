import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const CardPriceWrapper = styled(C.Inline)`
  flex-direction: column;
  align-items: flex-start;
  z-index: ${({ theme }) => theme.zIndex[2]};
`;

export const CardPriceTitle = styled(C.Typography)`
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
  display: block;
  color: ${({ theme }) => theme.palette.petrol};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      font: ${theme.typography.body2};
      margin-bottom: ${theme.spacing[8]};
    `
    )}
`;

export const CardPrice = styled(C.Typography)`
  display: block;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.palette.primary};

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
      font: ${theme.typography.h4};
    `
    )}
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
    font: ${theme.typography.h3};
  `
    )}
`;
