export const calculateJSONKeysArrayLengthSum = (
  jsonObject: Record<string, unknown>
): number => {
  let sum = 0;

  for (const key in jsonObject) {
    if (Array.isArray(jsonObject[key])) {
      sum += (jsonObject[key] as unknown[]).length;
    }
  }

  return sum;
};

export const generateNonEmptyQueryParamsFromObject = (
  searchParamsObject: Record<string, string | undefined>
): URLSearchParams => {
  const queryParams = new URLSearchParams();
  for (const [key, value] of Object.entries(searchParamsObject)) {
    if (value !== undefined && value !== '') {
      queryParams.set(key, value);
    }
  }
  return queryParams;
};
