import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import AccentBox from '@components/AccentBox';
import ArrowPattern from '@components/ArrowPattern';
import {
  CONTENT_PADDING_HORIZONTAL,
  contentPaddingHorizontal,
  contentWidthBoundary
} from '@theme/layout';

export const Container = styled(C.Stack)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.xLightGrey};
  padding-bottom: ${({ theme }) => theme.spacing[136]};
  overflow: hidden;
  ${contentPaddingHorizontal}
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[64]};
        padding-bottom: ${theme.spacing[80]};
      `
    )}
`;

export const GarageSegmentTitle = styled(C.Typography)`
  display: block;
  color: ${({ theme }) => theme.palette.petrol};
  margin: auto;
  font: ${({ theme }) => theme.typography.h2};
  margin-top: ${({ theme }) => theme.spacing[56]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        font: ${theme.typography.h4};
      `
    )}
`;

export const CardsWrapper = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[32]};
  ${contentWidthBoundary}
  flex-direction: row;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.spacing[56]};
  z-index: ${({ theme }) => theme.zIndex[2]};
  width: 100%;
  & > * {
    flex: 1;
  }
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        flex-direction: column;
      `
    )}
`;

export const CardInner = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`;

const CardFace = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.secondary};
  backface-visibility: hidden;
`;

export const CardFront = styled(CardFace)`
  color: ${({ theme }) => theme.palette.petrol};
  background-color: ${({ theme }) => theme.palette.snow};
`;

export const CardIconWrapper = styled(C.Inline)<{ $altBorder: string }>`
  width: fit-content;
  padding: ${({ theme }) => theme.spacing[16]};
  margin: ${({ theme }) => theme.spacing[24]};
  border: ${({ theme, $altBorder }) =>
    $altBorder ? theme.borders.primaryM : theme.borders.regularM};
  border-radius: 6px;
`;

export const CardContent = styled(C.Stack)`
  padding-left: ${({ theme }) => `${theme.spacing[24]}`};
  padding-right: ${({ theme }) => `${theme.spacing[24]}`};
`;

export const CardTitle = styled(C.Typography)`
  display: block;
  color: ${({ theme }) => theme.palette.petrol};
`;

export const CardDescription = styled(C.Typography)`
  margin: ${({ theme }) => `${theme.spacing[16]} 0`};
  display: block;
`;

const CardButton = styled(C.IconButton)`
  background-color: ${({ theme }) => theme.palette.primary};

  [data-fill='primary'] {
    fill: ${({ theme }) => theme.palette.snow};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const ArrowButton = styled(CardButton)`
  &:hover {
    & > * {
      transform: rotate(-45deg);
    }
  }
`;

export const SVGPattern = styled(ArrowPattern)`
  position: absolute;
  transform: scaleX(-1);
  left: -${CONTENT_PADDING_HORIZONTAL};
  bottom: ${({ theme }) => `-${theme.spacing[48]}`};
`;

export const AgeAccentBox = styled(AccentBox)`
  ${contentWidthBoundary}
  margin-top: ${({ theme }) => `${theme.spacing[40]}`};
  z-index: ${({ theme }) => theme.zIndex[2]};
`;
