import { Maybe } from 'graphql/jsutils/Maybe';

import * as S from './PriceField.styled';

type PriceFieldProps = {
  priceTitle: Maybe<string>;
  price: Maybe<string>;
};

const PriceField = ({ priceTitle, price }: PriceFieldProps) => {
  return (
    <S.CardPriceWrapper>
      <S.CardPriceTitle variant="body1">{priceTitle}</S.CardPriceTitle>
      <S.CardPrice variant="h3">{price}</S.CardPrice>
    </S.CardPriceWrapper>
  );
};

export default PriceField;
