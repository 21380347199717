import { Maybe } from 'graphql/jsutils/Maybe';

import { Feature } from '@api/contentful';
import { DribeConfig } from '@api/dribe';
import DownloadAppSection from '@components/DownloadAppSection';
import { DownloadAppSectionProps } from '@components/DownloadAppSection/DownloadAppSection';
import FaqSection from '@components/FaqSection';
import { FaqSectionProps } from '@components/FaqSection/FaqSection';
import GarageSegmentSection from '@components/GarageSegmentSection';
import { GarageSegmentSectionProps } from '@components/GarageSegmentSection/GarageSegmentSection';
import LandingHero, {
  LandingHeroProps
} from '@components/LandingHero/LandingHero';

import * as S from './LandingLayout.styled';

type LandingLayoutProps = {
  dribeConfig: Maybe<DribeConfig>;
  heroProps: LandingHeroProps;
  features?: Feature[];
  garageSegmentProps: Omit<GarageSegmentSectionProps, 'dribeConfig'>;
  downloadAppProps: DownloadAppSectionProps;
  faqProps: FaqSectionProps;
};

const LandingLayout = ({
  dribeConfig,
  heroProps,
  features,
  garageSegmentProps,
  downloadAppProps,
  faqProps
}: LandingLayoutProps) => {
  return (
    <div>
      <LandingHero {...heroProps} />
      <S.StyledFeatureCards features={features} />
      <DownloadAppSection {...downloadAppProps} />
      <GarageSegmentSection {...garageSegmentProps} dribeConfig={dribeConfig} />
      <FaqSection {...faqProps} />
    </div>
  );
};

export default LandingLayout;
