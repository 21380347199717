import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const FormContainer = styled.div`
  background: ${({ theme }) => theme.palette.snow};
  border-radius: 16px;
  padding-top: ${({ theme }) => theme.spacing[32]};
  padding-bottom: ${({ theme }) => theme.spacing[32]};
  padding-left: ${({ theme }) => theme.spacing[40]};
  padding-right: ${({ theme }) => theme.spacing[40]};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.secondary};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        width: 100%;
        padding-left: ${theme.spacing[32]};
        padding-right: ${theme.spacing[32]};
      `
    )}
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
      box-shadow: ${theme.shadows.secondary}
      `
    )}
`;

export const FormTitle = styled(C.Typography)`
  font: ${({ theme }) => theme.typography.h4};
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
     font: ${theme.typography.h5};
      `
    )};
`;

export const InlineSearch = styled(C.Inline)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing[20]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        flex-wrap: wrap;
      `
    )};
`;

export const LocationDropdown = styled(C.DropdownV2)`
  flex: 1;
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        width: 100%;
        flex: initial;
      `
    )};
`;

export const DateRangeInputDropdown = styled.div`
  flex: 1;
  .calendar {
    width: 40%;
    max-width: 900px;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndex[8]};
  }
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      width: 100%;
      flex: initial;
      .calendar {
        width: 80%;
      }
    `
    )};
`;

export const SearchButtonWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        width: 100%;
      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      width: 100%;
      margin-top: ${theme.spacing[24]};
      `
    )};
`;

export const SearchButton = styled(C.Button)`
  width: inherit;
  font-weight: normal;
`;
