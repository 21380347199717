import { IconButton } from '@dribe-io/ui-kit/components';

import { Asset, LandingPageFragment, Maybe } from '@api/contentful';
import QuickSearchForm from '@components/QuickSearchForm';
import { QuickSearchFormProps } from '@components/QuickSearchForm/QuickSearchForm';
import RouteNames from '@constants/RouteNames';
import LinkTitles from '@lib/rudderstack/LinkTitles';

import * as S from './LandingHero.styled';

export interface LandingHeroProps {
  heroTitleText: LandingPageFragment['heroTitleText'];
  heroBackgroundImage: Asset;
  heroBackgroundImageMobile: Asset;
  garageLabel: Maybe<string>;
  formProps: QuickSearchFormProps;
  className?: string;
}

export const LandingHero = ({
  heroTitleText,
  heroBackgroundImage,
  heroBackgroundImageMobile,
  garageLabel,
  formProps,
  className
}: LandingHeroProps) => {
  return (
    <S.FlexContainer
      className={className}
      justifyContent="center"
      alignItems="stretch"
    >
      <S.HeroContainer flexDirection="row">
        <S.HeroContent
          $backgroundImage={heroBackgroundImage.url}
          $backgroundImageMobile={heroBackgroundImageMobile.url}
        >
          <S.LandingHeroTitle title={heroTitleText} />
          <S.BottomContainer
            flexDirection="column"
            justifyContent="stretch"
            alignItems="stretch"
          >
            <QuickSearchForm {...formProps} />
          </S.BottomContainer>
        </S.HeroContent>
        <S.HeroSidebar
          title={LinkTitles.LANDING_HERO_GARAGE_LINK}
          href={`${RouteNames.GARAGE}`}
        >
          <IconButton
            className="garage-link"
            iconName="arrowRightThin"
            iconStyle={{ color: 'orange' }}
          />
          <S.HeroSidebarTitle
            variant="h5"
            textTransform="uppercase"
            color="snow"
          >
            {garageLabel}
          </S.HeroSidebarTitle>
        </S.HeroSidebar>
      </S.HeroContainer>
    </S.FlexContainer>
  );
};

export default LandingHero;
