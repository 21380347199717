import { useRouter } from 'next/router';

import { NextPage, InferGetStaticPropsType, GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';

import { Asset, Feature, getLandingPage } from '@api/contentful';
import * as D from '@api/dribe';
import Banner from '@components/Banner';
import { DownloadAppSectionProps } from '@components/DownloadAppSection/DownloadAppSection';
import { FaqSectionProps } from '@components/FaqSection/FaqSection';
import { GarageSegmentSectionProps } from '@components/GarageSegmentSection/GarageSegmentSection';
import { LandingHeroProps } from '@components/LandingHero/LandingHero';
import withLayout, { getStaticLayoutProps } from '@hoc/withLayout';
import LandingLayout from '@layouts/LandingLayout';
import { FALLBACK_LOCALE } from '@utils/constants';
import { formatContentfulSeoToNextSeo } from '@utils/seoFormatter';

const Index: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  page,
  layout
}) => {
  const { pathname } = useRouter();

  return (
    <>
      <NextSeo
        {...formatContentfulSeoToNextSeo(
          page?.seoMetadata?.title || page?.title,
          page?.seoMetadata,
          pathname
        )}
      />
      {!!page.announcement && (
        <Banner
          isB2B={layout?.isB2B}
          content={page.announcement.content?.json}
        />
      )}
      <LandingLayout
        dribeConfig={layout?.dribeConfig}
        heroProps={page.heroProps}
        features={page.features}
        downloadAppProps={page.downloadAppProps}
        faqProps={page.faqProps}
        garageSegmentProps={page.garageSegmentProps}
      />
    </>
  );
};

export const getStaticProps = async ({
  locale,
  preview = false
}: GetStaticPropsContext) => {
  const result = await getLandingPage(
    {
      variables: {
        tagId: process.env.CONTENTFUL_TAG_ID,
        locale,
        preview
      }
    },
    [preview]
  );

  const collection = result.data.landingPageCollection;

  if (!collection?.items || collection.items.length === 0) {
    return {
      notFound: true
    };
  }

  const data = collection.items[0];

  if (!data) {
    return {
      notFound: true
    };
  }

  const locations = await D.getLocations({});

  const heroProps: LandingHeroProps = {
    heroTitleText: data.heroTitleText,
    heroBackgroundImage: data.heroBackgroundImage as Asset,
    heroBackgroundImageMobile: data.heroBackgroundImageMobile as Asset,
    garageLabel: data.garageLabel,
    formProps: {
      locations: locations?.data?.locations,
      formTitle: data.bookingFormTitle,
      locationDropdownLabel: data.locationDropdownLabel,
      locationDropdownPlaceholder: data.locationDropdownPlaceholder,
      dateInputLabel: data.dateInputLabel,
      dateInputPlaceholder: data.dateInputPlaceholder,
      searchButtonLabel: data.bookingFormSearchText
    }
  };

  const features = data.featuresFlipCardsCollection?.items as
    | Feature[]
    | undefined;

  const downloadAppProps: DownloadAppSectionProps = {
    title: data.downloadAppTitle,
    featureList: data.appFeatures,
    description: data.appDescription,
    images: data.appImagesCollection?.items as Asset[]
  };

  const faqProps: FaqSectionProps = {
    faqTitle: data.faqSectionTitle,
    faqItems: data.faqSectionItemsCollection
  };

  const {
    data: { budgets, populars, electrics }
  } = await D.getGarageSegments({
    variables: { propellant: [data.electricPropellantValue || ''] }
  });

  const garageSegmentProps: Omit<GarageSegmentSectionProps, 'dribeConfig'> = {
    budget: budgets?.data?.[0]?.pricing?.realWorldRate ?? 0,
    popular: populars?.data?.[0]?.pricing?.realWorldRate ?? 0,
    electric: electrics?.data?.[0]?.pricing?.realWorldRate ?? 0,
    ageRequirementsText: data.ageRequirementsAccentBox,
    budgetCategoryMinPrice: budgets?.meta?.aggregates?.minPrice?.value ?? 0,
    budgetCategoryMaxPrice: data.budgetCategoryMaxPrice ?? 0,
    electricPropellantValue: data.electricPropellantValue ?? '',
    cardPricesFromText: data.cardPricesFromText
  };

  const page = {
    announcement: data.announcement,
    title: data.title,
    heroProps,
    features,
    downloadAppProps,
    faqProps,
    garageSegmentProps,
    seoMetadata: data.seoMetadata
  };

  return {
    revalidate: 10,
    props: {
      page,
      ...(await serverSideTranslations(
        locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? FALLBACK_LOCALE
      )),
      ...(await getStaticLayoutProps(locale, preview))
    }
  };
};

export default withLayout(Index);
