import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { Typography } from '@dribe-io/ui-kit/components';

import { useTranslation } from 'next-i18next';

import { Maybe, DribeConfig } from '@api/dribe';
import { Card, CardProps } from '@components/Card';
import { trim } from '@utils/stringUtils';

import * as S from './GarageSegmentSection.styled';
import PriceField from './PriceField/PriceField';

type GarageCard = 'budget' | 'popular' | 'electric';

export interface GarageSegmentSectionProps {
  dribeConfig: Maybe<DribeConfig>;
  budget: Maybe<number> | null;
  popular: Maybe<number> | null;
  electric: Maybe<number> | null;
  ageRequirementsText: Maybe<string>;
  budgetCategoryMinPrice: number;
  budgetCategoryMaxPrice: number;
  electricPropellantValue: string;
  cardPricesFromText: Maybe<string>;
}

type Section = {
  icon: string;
  link: string;
};

export const GarageSegmentSection = ({
  dribeConfig,
  budget,
  popular,
  electric,
  ageRequirementsText,
  budgetCategoryMinPrice,
  budgetCategoryMaxPrice,
  electricPropellantValue,
  cardPricesFromText
}: GarageSegmentSectionProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const sections: Record<string, Section> = useMemo(
    () => ({
      budget: {
        icon: 'tag',
        link:
          // To prevent franchisees from breaking this via Contentful
          budgetCategoryMinPrice === 0
            ? '/garage'
            : `/garage?minPrice=${budgetCategoryMinPrice}` +
              (budgetCategoryMaxPrice > budgetCategoryMinPrice
                ? `&maxPrice=${budgetCategoryMaxPrice}`
                : '')
      },
      popular: {
        icon: 'starEmpty',
        link: '/garage?sortBy=popularity&sortDir=desc'
      },
      electric: {
        icon: 'flash',
        link: !electricPropellantValue
          ? '/garage'
          : `/garage?fuelType=${electricPropellantValue}`
      }
    }),
    [budgetCategoryMaxPrice, budgetCategoryMinPrice, electricPropellantValue]
  );

  const [segments, setSegments] = useState<Record<string, CardProps> | null>(
    null
  );

  const fetchSegments = useCallback(() => {
    const garageCards: GarageCard[] = ['budget', 'popular', 'electric'];

    const prices: { [key in GarageCard]: Maybe<number> } = {
      budget,
      popular,
      electric
    };

    const updatedSegments: Record<string, CardProps> = {};
    for (const elem of garageCards) {
      const title = t(`landingPage.garageSegmentSection.${elem}.title`);
      const description = t(
        `landingPage.garageSegmentSection.${elem}.description`
      );

      const price = Math.round((prices[elem] ?? 0) / 100);
      const formattedPrice = `${price.toLocaleString(locale)} ${t(
        `numberFormatOptions.${dribeConfig?.countryCode}.currency`
      )}`;

      updatedSegments[elem] = {
        frontTitle: title,
        frontDescription: trim(description, 180),
        frontFooter: (
          <PriceField priceTitle={cardPricesFromText} price={formattedPrice} />
        ),
        frontIconName: sections[elem].icon,
        link: sections[elem].link,
        hidden: price === 0
      };
    }
    return updatedSegments;
  }, [
    budget,
    cardPricesFromText,
    dribeConfig?.countryCode,
    electric,
    locale,
    popular,
    sections,
    t
  ]);

  useEffect(() => {
    const fetchData = () => {
      const fetchedSegments = fetchSegments();
      setSegments(fetchedSegments);
    };
    fetchData();
  }, [fetchSegments]);

  if (!segments) {
    return <></>;
  }

  return (
    <S.Container>
      <S.GarageSegmentTitle>
        {t('landingPage.garageSegmentSection.title')}
      </S.GarageSegmentTitle>
      <S.CardsWrapper justifyContent="center">
        {Object.values(segments).map((segment, index) => (
          <Card key={index} {...segment} />
        ))}
      </S.CardsWrapper>
      {!!ageRequirementsText && (
        <S.AgeAccentBox>
          <Typography variant="body1">{ageRequirementsText}</Typography>
        </S.AgeAccentBox>
      )}
      <S.SVGPattern />
    </S.Container>
  );
};
