import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import HeroTitle from '@components/HeroTitle';
import { TrackedLink } from '@components/Tracked';
import { MAX_WIDTH, contentPaddingHorizontal } from '@theme/layout';

export const FlexContainer = styled(C.Inline)`
  aspect-ratio: 2/1;
  max-height: 916px;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing[40]};
  padding-right: ${({ theme }) => theme.spacing[32]};
  padding-left: ${({ theme }) => theme.spacing[32]};
  ${contentPaddingHorizontal};
  background: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.palette.xLightGrey} 50%)`};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        height: 700px;
      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        height: 632px;
        padding: ${theme.spacing[16]}  ${theme.spacing[16]}  ${theme.spacing[32]}  ${theme.spacing[16]};
      `
    )};

  & > * {
    max-width: ${MAX_WIDTH};
  }
`;

export const HeroContainer = styled(C.Inline)`
  height: 100%;
  width: 100%;
  max-height: 876px;
  border-radius: 16px;
  background: ${({ theme }) =>
    `linear-gradient(to left, ${theme.palette.orange} 80px, transparent 0)`};
  margin-bottom: ${({ theme }) => theme.spacing[56]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        margin-bottom: ${theme.spacing[96]};
        height: 120%;
      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        height: 100%;
      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        background: transparent;
      `
    )};
`;

export const HeroContent = styled.div<{
  $backgroundImage?: string | null;
  $backgroundImageMobile?: string | null;
}>`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-radius: 16px;
  padding: ${({ theme }) => `${theme.spacing[64]} 0 ${theme.spacing[160]} 0`};

  // 2nd background fallback
  background: ${({ theme }) => theme.palette.snow};
  // 1st background fallback
  background: url(${({ $backgroundImage }) => $backgroundImage}) bottom / cover
    no-repeat;
  background:
    linear-gradient(
      to bottom,
      ${({ theme }) => theme.palette.xLightGrey},
      transparent 46%
    ),
    url(${({ $backgroundImage }) => $backgroundImage}) bottom / cover no-repeat;

  min-height: 420px;

  ${({ theme, $backgroundImageMobile }) =>
    theme.breakpoints.down(
      'sm',
      `
        background: url(${$backgroundImageMobile}) bottom /
          cover no-repeat;
        background: linear-gradient(
            to bottom,
            ${theme.palette.xLightGrey},
            transparent 46%
          ),
          url(${$backgroundImageMobile}) bottom / cover
            no-repeat;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'xl',
      `
        padding: ${theme.spacing[24]} 0 ${theme.spacing[96]} 0;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        padding: ${theme.spacing[24]} 0 ${theme.spacing[64]} 0;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding: ${theme.spacing[24]} 0 ${theme.spacing[80]} 0;
        background-position: initial;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        padding: 0;
        width: 100%;
        justify-content: space-between;
        background-position: bottom;
      `
    )};
`;

export const LandingHeroTitle = styled(HeroTitle)`
  width: 80%;
  padding-left: ${({ theme }) => theme.spacing[96]};
  padding-right: ${({ theme }) => theme.spacing[8]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
          width: 80%;
          padding-left: ${theme.spacing[24]};
        `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
      padding-left: ${theme.spacing[8]};
      padding-top: ${theme.spacing[8]};
          width: 90%;
        `
    )};
`;

export const HeroSidebar = styled(TrackedLink)`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
  hyphens: none;
  width: 64px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        display: none;
      `
    )};

  .garage-link {
    margin-top: ${({ theme }) => theme.spacing[32]};
    transform: rotate(0);
    transition: ${({ theme }) => theme.transition('transform').ease.sm};
  }

  &:hover {
    .garage-link {
      background-color: ${({ theme }) => theme.palette.snow};
      & {
        transform: rotate(-45deg);
      }
    }
  }
`;

export const HeroSidebarTitle = styled(C.Typography)`
  padding-right: ${({ theme }) => theme.spacing[64]};
  transform: rotate(-90deg);
`;

export const BottomContainer = styled(C.Inline)`
  width: 80%;
  margin: 0 auto;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      width: 90%;
    `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
      width: 100%;
    `
    )};
`;
