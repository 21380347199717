import React, { ReactNode } from 'react';

import * as S from './AccentBox.styled';

type AccentBoxProps = {
  children: ReactNode;
  className?: string;
};

const AccentBox = ({ children, className }: AccentBoxProps) => {
  return (
    <S.AccentBox className={className} py={24} justifyContent={'center'}>
      {children}
    </S.AccentBox>
  );
};

export default AccentBox;
