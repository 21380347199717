import styled from 'styled-components';

import FeatureCards from '@components/FeatureCards';
import { contentWidthBoundary } from '@theme/layout';

export const StyledFeatureCards = styled(FeatureCards)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing[104]};
  ${contentWidthBoundary}
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        padding-top: ${theme.spacing[16]};
      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top:  ${theme.spacing[32]};
        padding-bottom: ${theme.spacing[128]};
      `
    )};
`;
