import { Typography } from '@dribe-io/ui-kit/components';

import { useTranslation } from 'next-i18next';

import { Maybe, Asset } from '@api/contentful';
import { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';

import * as S from './DownloadAppSection.styled';

export interface DownloadAppSectionProps {
  title: Maybe<string>;
  featureList: Maybe<Array<Maybe<string>>>;
  description: Maybe<string>;
  images: Maybe<Asset[]>;
}

const DownloadAppSection = ({
  title,
  featureList,
  description,
  images
}: DownloadAppSectionProps) => {
  const { t } = useTranslation();
  const imagesWithUrls = images?.filter(i => i.url);

  return (
    <S.Container>
      <S.ImageContainer flex={1} justifyContent="center">
        <S.ImageStack>
          {imagesWithUrls?.map((image, index) => {
            const width = 334 - index * 11;
            const height = 717 - index * 23;
            return (
              <S.StackImage
                key={`${image.sys.id}_${index}`}
                src={image.url || ''}
                alt={image.description || ''}
                quality={100}
                width={width}
                height={height}
              />
            );
          })}
        </S.ImageStack>
      </S.ImageContainer>

      <S.Content flex={1}>
        <S.Title variant="h2" element="h2">
          {title}
        </S.Title>
        <S.FeatureList>
          {featureList &&
            featureList.map((feature, index) => (
              <S.FeatureItem key={`${feature}_${index}`}>
                <S.FeatureItemInnerContainer>
                  <S.FeatureItemIcon name="check" color="orange" size={24} />
                  <Typography variant="sub1">{feature}</Typography>
                </S.FeatureItemInnerContainer>
              </S.FeatureItem>
            ))}
        </S.FeatureList>
        <S.LastFeatureItem variant="sub1" element="p">
          {t('landingPage.downloadApp.andMuchMore')}
        </S.LastFeatureItem>
        <S.Description variant="body2" element="p">
          {description}
        </S.Description>
        <TrackedLink
          href={RouteNames.DOWNLOAD}
          title={t('landingPage.downloadApp.linkTitle')}
        >
          <S.DownloadButton text={t('landingPage.downloadApp.buttonLabel')} />
        </TrackedLink>
      </S.Content>
    </S.Container>
  );
};
export default DownloadAppSection;
