import Image from 'next/image';

import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import { contentPaddingHorizontal, contentWidthBoundary } from '@theme/layout';

export const Container = styled(C.Inline)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[128]};
  padding-bottom: ${({ theme }) => theme.spacing[152]};
  gap: ${({ theme }) => theme.spacing[32]};
  background: ${({ theme }) => theme.palette.snow};
  ${contentPaddingHorizontal}
  ${contentWidthBoundary}

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding: ${theme.spacing[72]} ${theme.spacing[32]} ${theme.spacing[80]} ${theme.spacing[32]};
        gap: ${theme.spacing[88]};
        flex-direction: column;
      `
    )}

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding: ${theme.spacing[72]} ${theme.spacing[16]} ${theme.spacing[80]} ${theme.spacing[16]};
      `
    )}
`;

export const ImageContainer = styled(C.Inline)`
  order: 0;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        order: 1;
      `
    )}
`;

export const ImageStack = styled(C.Inline)`
  position: relative;
  justify-content: center;
  padding-right: ${({ theme }) => theme.spacing[120]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        padding-right: 0;
      `
    )}
`;

export const StackImage = styled(Image)`
  border-radius: 16px;

  &:nth-child(1) {
    top: 0;
    left: 0;
    z-index: 3;
    filter: drop-shadow(6px 6px 6px ${({ theme }) => theme.palette.mediumGrey});
  }
  &:nth-child(2) {
    top: 23px;
    left: 80px;
    z-index: 2;
    filter: drop-shadow(6px 6px 6px ${({ theme }) => theme.palette.mediumGrey});
  }
  &:nth-child(3) {
    left: 140px;
    top: 46px;
    z-index: 1;
  }

  &:not(:first-child) {
    position: absolute;
    display: block;
  }

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        &:not(:first-child) {
          display: none;
        }
      `
    )}
`;

export const Content = styled(C.Inline)`
  order: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[48]};

  ${({ theme }) => theme.breakpoints.up('lg', 'width: 40%;')}

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        order: 0;
      `
    )}
`;

export const Title = styled(C.Typography)`
  font: ${({ theme }) => theme.typography.h4};
  ${({ theme }) => theme.breakpoints.up('sm', `font: ${theme.typography.h2}`)};
`;

export const FeatureList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  font: ${({ theme }) => theme.typography.sub1};
  ${({ theme }) => theme.breakpoints.up('sm', `font: ${theme.typography.h5}`)};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      padding-left: ${theme.spacing[24]};
      `
    )}
`;

export const FeatureItem = styled.li`
  color: ${({ theme }) => theme.palette.petrol};

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing[24]};
  }
`;

export const FeatureItemInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FeatureItemIcon = styled(C.Icon)`
  padding-right: ${({ theme }) => theme.spacing[16]};
`;

export const LastFeatureItem = styled(C.Typography)`
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.up('sm', `font: ${theme.typography.h5}`)};
`;

export const Description = styled(C.Typography)`
  ${({ theme }) =>
    theme.breakpoints.up('sm', `font: ${theme.typography.body1}`)};
`;

export const DownloadButton = styled(C.Button)`
  width: fit-content;
`;
