import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const AccentBox = styled(C.Stack)`
  border: ${({ theme }) => theme.borders.regularM};
  border-radius: 16px;
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[72]}`};
  text-align: center;
  width: 100%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
      padding: ${theme.spacing[16]};
    `
    )};
`;
